import { request } from "@/request/http";

/**
 * @name: 虚拟实验分类管理接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取学校列表
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },
  // 获取虚拟实验分类列表
  getExpLabList: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabList`, data);
  },
  //   添加/修改虚拟实验
  updateExp: (data) => {
    return request("POST", `/index.php/adminExp/updateExp`, data);
  },
  // 获取用户列表
  getUserList: (data) => {
    return request("POST", `/index.php/adminUser/getUserList`, data);
  },
  // 获取文章列表
  getArticleList: (data) => {
    return request("POST", `/index.php/adminExp/getArticleList`, data);
  },
  // 添加/修改虚拟实验分类数据
  updateExpLabData: (data) => {
    return request("POST", `/index.php/adminExp/updateExpLabData`, data);
  },
  // 获取修改虚拟实验分类数据
  getExpLabDataById: (data) => {
    return request("POST", `/index.php/adminExp/getExpLabDataById`, data);
  },
  // 删除虚拟实验分类数据
  delExpById: (data) => {
    return request("POST", `/index.php/adminExp/delExpById`, data);
  },
  // 修改排序
  updateSortBy: (data) => {
    return request("POST", `/index.php/adminExp/updateSortBy`, data);
  },
  // 修改用户状态
  updateUserStatus: (data) => {
    return request("POST", `/index.php/adminUser/updateUserStatus`, data);
  },
  // 删除用户
  deleteUser: (data) => {
    return request("POST", `/index.php/adminUser/deleteUser`, data);
  },
  // 获取授权虚拟实验列表
  getGrantExpList: (data) => {
    return request("POST", `/index.php/adminUser/getGrantExpList`, data);
  },
  updateUserExp: (data) => {
    return request("POST", `/index.php/adminUser/updateUserExp`, data);
  },
  getUserInfoById: (data) => {
    return request("POST", `/index.php/adminUser/getUserInfoById`, data);
  },
  updateUserInfo: (data) => {
      return request("POST", `/index.php/adminUser/updateUserInfo`, data);
  },
  // 获取授权虚拟实验列表
  getLockExpList: (data) => {
    return request("POST", `/index.php/adminUser/getLockExpList`, data);
  },
  updateUserExpLock: (data) => {
    return request("POST", `/index.php/adminUser/updateUserExpLock`, data);
  },
  // 获取充值积分列表
  getChargeList: (data) => {
    return request("POST", `/index.php/adminUser/getChargeList`, data);
  },
  // 给用户充值
  chargeToUser: (data) => {
    return request("POST", `/index.php/adminUser/chargeToUser`, data);
  },
  // 获取用户统计
  getStatisticsUser: (data) => {
    return request("POST", `/index.php/adminUser/getStatisticsUser`, data);
  },
  // 获取用户虚拟实验操作记录
  getExpDataListById: (data) => {
    return request("POST", `/index.php/adminUser/getExpDataListById`, data);
  },
  // 用户发微信推送
  sendWxMessage: (data) => {
    return request("POST", `/index.php/adminUser/sendWxMessage`, data);
  },
};