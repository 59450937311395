<template >
  <div class="useList">
    <div class="logButton clearFix">
      <div class="export">
        <!-- <el-button class="themeButton" @click="showDialog">添加虚拟实验</el-button> -->
        <el-select filterable remote clearable v-model="is_login" placeholder="请选择状态" @change="handleSearchColleague"
          class="">
          <el-option v-for="item in managerstatusList" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="searchLab">
        <div class="searchContent">
          <el-select filterable remote clearable v-model="type" @change="handleSearchColleague" placeholder="请选择身份"
            class="">
            <el-option v-for="item in typeList" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="searchContent">
          <el-select v-model="school_id" @change="handleSearchColleague" filterable remote clearable placeholder="请搜索选择学校"
            :remote-method="getschoolList">
            <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <!-- <div class="searchContent">
                <el-button class="themeButton" @click="static">统计</el-button>
              </div> -->
        <div class="info">
          <div class="uncheckNum">{{ explabListTotal }}</div>
          <!-- <div class="uncheckText">个实验信息不完整 / 共</div>
                <div class="uncheckNum">{{explabListTotal}}</div> -->
          <div class="uncheckText">总人数</div>
          <el-button class="themeButton" @click="openExportDialog">导出学校数据</el-button>
        </div>
      </div>

      <div class="search">
        <div class="searchContent">
          <el-input placeholder="请输入昵称/电话/姓名/学号" clearable @clear="getData" v-model="searchContent"
            class="input-with-select"></el-input>
        </div>
        <el-button class="themeButton" @click="handleSearchColleague">搜索</el-button>
      </div>
    </div>
    <!-- 用户列表 -->
    <el-card>
      <el-table ref="multipleTable" :data="explabList" tooltip-effect="dark" style="width: 100%">
        <!-- <el-table-column
                      type="index"
                      :index="indexMethod"
                      label="序号"
                      width="60">
                    </el-table-column> -->
        <el-table-column label="姓名" prop="name" min-width="60">
        </el-table-column>
        <el-table-column label="学号" prop="personal_id" min-width="90">
        </el-table-column>
        <el-table-column label="学校" prop="schoolName" min-width="90">
        </el-table-column>
        <el-table-column label="身份">
          <template slot-scope="scope">
            <el-select @change="updateUserStatus(scope.row)" v-model="scope.row.type" placeholder="请选择身份" class="">
              <el-option v-for="item in typeList" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <!-- <div v-if="scope.row.type == 0">学生</div>
                        <div v-if="scope.row.type == 1">老师</div>
                        <div v-if="scope.row.type == 9">管理员</div> -->
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="120">
          <template slot-scope="scope">
            <el-select @change="updateUserStatus(scope.row)" v-model="scope.row.islogin" placeholder="请选择状态">
              <div v-if="scope.row.type == 9">
                <el-option v-for="item in managerstatusList" :label="item.name" :value="item.id"></el-option>
              </div>
              <div v-if="scope.row.type == 1">
                <el-option v-for="item in teacherstatusList" :label="item.name" :value="item.id"></el-option>
              </div>
              <div v-if="scope.row.type == 0">
                <el-option v-for="item in studentstatusList" :label="item.name" :value="item.id"></el-option>
              </div>
              <div v-if="scope.row.type == 5">
                <el-option v-for="item in loginstatusList" :label="item.name" :value="item.id"></el-option>
              </div>

            </el-select>
            <!-- <div v-if="scope.row.type == 0">拉黑</div>
                        <div v-if="scope.row.type == 1">刚进入</div>
                        <div v-if="scope.row.type == 2">选择身份</div>
                        <div v-if="scope.row.type == 3">学生已填写信息</div>
                        <div v-if="scope.row.type == 4">学生已上传面部照片</div>
                        <div v-if="scope.row.type == 5">学生已上传身份证</div>
                        <div v-if="scope.row.type == 8">老师已填证件</div>
                        <div v-if="scope.row.type == 9">审核通过</div> -->
          </template>
        </el-table-column>
        <el-table-column label="教师证">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.personal_card != '' && scope.row.personal_card != null && scope.row.personal_card != 'null' && scope.row.personal_card != 'undefined'"
              @click="preview(scope.row.personal_card)" :src="scope.row.personal_card">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="昵称">
          <template slot-scope="scope">
            <div class="usernickName">{{ scope.row.nickname ? scope.row.nickname : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="电话" prop="tel" min-width="100">
        </el-table-column>
        <el-table-column label="备注"  min-width="70">
          <template slot-scope="scope">
            <div class="userDesc" v-html="scope.row.desc" @click="showteacherDesc(scope.row)"></div>
          </template>
        </el-table-column>
        <el-table-column label="积分" min-width="70">
          <template slot-scope="scope">
            <div class="selectText" @click="showBalance(scope.row.id)">{{ scope.row.balance }}</div>
            <!-- <el-input @blur="updateUserStatus(scope.row)" v-model="scope.row.balance"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="注册时间">
          <template slot-scope="scope">
            <div class="selectText">{{ formatDate(scope.row.time) }}</div>
            <!-- <el-input @blur="updateUserStatus(scope.row)" v-model="scope.row.balance"></el-input> -->
          </template>
        </el-table-column>
        <el-table-column label="授权项目"  min-width="70">
          <template slot-scope="scope">
            <span class="selectText" @click="getGrantList(scope.row.id)">{{ scope.row.grantNum }} |</span>
            <span class="selectText" @click="gograntProject(scope.row.id)"> 添加</span>
          </template>
        </el-table-column>
        <el-table-column label="解锁项目">
          <template slot-scope="scope">
            <span class="selectText" @click="getLockList(scope.row.id)">{{ scope.row.lockNum }} |</span>
            <span class="selectText" @click="golockProject(scope.row.id)"> 添加</span>
          </template>
        </el-table-column>
        <el-table-column label="虚拟活跃度">
          <template slot-scope="scope">
            <div class="selectText" @click="openVirtualDialog(scope.row.id)">{{ scope.row.expDataNum }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="170">
          <template slot-scope="scope">
            <div class="operatorItem floatLeft" @click="editExp(scope.row.id)">编辑</div>
            <div class="operatorItem floatLeft" @click="delExpById(scope.row.id, scope.row.name)">删除</div>
            <div class="operatorItem floatLeft" @click="static(scope.row.id)">统计</div>
            <div class="operatorItem floatLeft" @click="sendWxMessageDialog(scope.row.id)">发通知</div>
            <!-- <div class="operatorItem floatLeft">编辑</div>
                            <div class="operatorItem floatLeft">删除</div> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @current-change="getData" :current-page.sync="currentPage" background :page-size="pageSize"
        layout="prev, pager, next" :total="explabListTotal">
      </el-pagination>
    </el-card>
    <!-- 教师证弹窗 -->
    <el-dialog title="教师证" :visible.sync="teachercartVisible" width="30%">
      <img class="previewImg" :src="previewImg" alt="">
    </el-dialog>
    <!-- 备注弹窗 -->
    <el-dialog title="备注" :visible.sync="teacherdescVisible" width="30%">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="info.desc"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="teacherdescVisible = false">取消</el-button>
        <el-button class="themeButton" @click="updateuserDesc">确定</el-button>
      </span>
    </el-dialog>
    <!-- 授权项目弹窗 -->
    <el-dialog class="grantBox" title="授权项目" :visible.sync="grantVisible" @close="closeVisible" width="70%">
      <div class="search">
        <div class="searchContent">
          <el-select v-model="grant_lab_id" clearable @clear="gograntProject(user_id)" placeholder="请搜索选择实验类别">
            <el-option v-for="item in labList" :key="item.lab_id" :label="item.lab_name" :value="item.lab_id"></el-option>
          </el-select>
        </div>
        <div class="searchContent">
          <el-input placeholder="请输入实验名称！" clearable @clear="gograntProject(user_id)" v-model="grant_search"
            class="input-with-select"></el-input>
        </div>
        <el-button class="themeButton" @click="gograntProject(user_id)">搜索</el-button>
      </div>
      <div class="checkAll" style="top: 13.5vh;"><el-checkbox v-model="checkedAll" @change="toggleAll"></el-checkbox> 全选</div>
      <el-table ref="multipleTable" :data="tableData" row-key="lab_id" :expand-row-keys="expandedkeys"
        :tree-props="{ children: 'explist', hasChildren: 'hasChildren' }">
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.expliststatus" @change="toggleChecked(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="lab_id" label="分类id" min-width="30">
        </el-table-column>
        <el-table-column prop="lab_name" label="分类名称">
        </el-table-column>

        <!-- <el-table-column
                prop="buyUsername"
                min-width="30"
                label="购买者">
              </el-table-column> -->
        <el-table-column prop="username" min-width="30" label="开发者">
        </el-table-column>
        <el-table-column prop="schoolName" min-width="30" label="学校">
        </el-table-column>
        <el-table-column label="简介">
          <template slot-scope="scope">
            <div v-html="scope.row.des" style="overflow:hidden;text-overflow:ellipsis;height: 25px;"></div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="grantVisible = false">取消</el-button>
        <el-button class="themeButton" @click="grantProject">确定</el-button>
      </span>
    </el-dialog>
    <!-- 解锁项目弹窗 -->
    <el-dialog class="grantBox" title="解锁项目" :visible.sync="lockVisible" @close="closeVisible" width="70%">
      <div class="search">
        <div class="searchContent">
          <el-select v-model="lab_id" clearable @clear="golockProject(user_id)" placeholder="请搜索选择实验类别">
            <el-option v-for="item in labList" :key="item.lab_id" :label="item.lab_name" :value="item.lab_id"></el-option>
          </el-select>
        </div>
        <div class="searchContent">
          <el-input placeholder="请输入实验名称！" clearable @clear="golockProject(user_id)" v-model="search"
            class="input-with-select"></el-input>
        </div>
        <el-button class="themeButton" @click="golockProject(user_id)">搜索</el-button>
      </div>
      <div class="checkAll" style="top: 13.5vh;"><el-checkbox v-model="checkedAllLock" @change="toggleAllLock"></el-checkbox> 全选</div>
      <el-table ref="multipleTable" :data="tableDataLock" row-key="lab_id" :expand-row-keys="lockkeys"
        :tree-props="{ children: 'explist', hasChildren: 'hasChildren' }">
        <el-table-column width="70">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.buystatus" @change="toggleCheckedLock(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="lab_id" label="分类id" min-width="30">
        </el-table-column>
        <el-table-column prop="lab_name" label="分类名称">
        </el-table-column>
        <el-table-column prop="buyUsername" min-width="30" label="购买者">
        </el-table-column>
        <el-table-column prop="username" min-width="30" label="开发者">
        </el-table-column>
        <el-table-column prop="schoolName" min-width="30" label="学校">
        </el-table-column>
        <el-table-column label="简介">
          <template slot-scope="scope">
            <div v-html="scope.row.des" style="overflow:hidden;text-overflow:ellipsis;height: 20px;"></div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="lockVisible = false">取消</el-button>
        <el-button class="themeButton" @click="lockProject">确定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户弹窗 -->
    <el-dialog class="edituserBox" title="编辑用户" :visible.sync="edituserVisible" width="30%">
      <el-form :rules="rules" :model="AddFormData" label-width="150px" label-position="left">
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="AddFormData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="学号" prop="personal_id">
          <el-input clearable v-model="AddFormData.personal_id" placeholder="请输入学号"></el-input>
        </el-form-item>
        <el-form-item label="学校" prop="school_id">
          <el-select v-model="AddFormData.school_id" filterable remote placeholder="请搜索选择学校"
            :remote-method="getUserSchoolList">
            <el-option v-for="item in userschoolList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="edituserVisible = false">取消</el-button>
        <el-button class="themeButton" @click="edituser">确定</el-button>
      </span>
    </el-dialog>
    <!-- 统计弹窗 -->
    <el-dialog title="统计" :visible.sync="staticVisible" width="50%">
      <div class="datePicker">
        <div class="">
          <el-date-picker class="datepickerInput" v-model="staticDate" type="daterange" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </div>
        <div>
          <el-button class="themeButton" @click="staticuser">统计</el-button>
        </div>
      </div>
      <div class="staticBox" v-show="lockNumber !== ''">
        <div>
          解锁人次
        </div>
        <div class="lockNumber">
          {{ lockNumber }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="staticVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="grantListTitle" :visible.sync="grantListVisible" width="80%">
      <div>
        <div class="dialog-search">
          <span><el-input v-model="grantSearch" placeholder="请输入内容"></el-input></span>
          <span><el-button type="primary" @click="getGrantList()">搜索</el-button></span>
        </div>
        <div style="position: absolute;left: 30px;top: 122px;">
          <el-checkbox v-model="checkedAll" @change="grantToggleAll"></el-checkbox> 
          全选
        </div>
        <el-table
          :data="grantList"
          stripe
          style="width: 100%">
          <el-table-column width="40">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.check" ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="实验ID"
            width="80">
          </el-table-column>
          <el-table-column
            prop="name"
            label="实验名称"
            width="280">
          </el-table-column>
          <el-table-column
            prop="labName"
            label="分类名称"
            width="150">
          </el-table-column>
          <el-table-column
            prop="createName"
            label="开发者"
            width="150">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="学校"
            width="240">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="简介">
            <template slot-scope="scope">
              <div v-html="scope.row.des" style="overflow:hidden;text-overflow:ellipsis;height: 20px;"></div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="getGrantListPage" :current-page.sync="grantCurrentPage" background :page-size="grantPageSize"
          layout="prev, pager, next" :total="grantTotal">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" style="color: #FFF;background-color: #F56C6C;" class="themecancelButton" @click="grantExpDelete">删除</el-button>
        <el-button class="themecancelButton" @click="grantListVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="locktListTitle" :visible.sync="lockListVisible" width="80%">
      <div>
        <div class="dialog-search">
          <span><el-input v-model="lockSearch" placeholder="请输入内容"></el-input></span>
          <span><el-button type="primary" @click="getLockList('')">搜索</el-button></span>
        </div>
        <div style="position: absolute;left: 30px;top: 122px;">
          <el-checkbox v-model="lockCheckedAll" @change="lockToggleAll"></el-checkbox> 
          全选
        </div>
        <el-table
          :data="lockList"
          stripe
          style="width: 100%">
          <el-table-column width="40">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.check" ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            prop="id"
            label="实验ID"
            width="80">
          </el-table-column>
          <el-table-column
            prop="name"
            label="实验名称"
            width="280">
          </el-table-column>
          <el-table-column
            prop="labName"
            label="分类名称"
            width="150">
          </el-table-column>
          <el-table-column
            prop="createName"
            label="开发者"
            width="180">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="学校"
            width="240">
          </el-table-column>
          <el-table-column
            prop="schoolName"
            label="简介">
            <template slot-scope="scope">
              <div v-html="scope.row.des" style="overflow:hidden;text-overflow:ellipsis;height: 20px;"></div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="getLockListPage" :current-page.sync="lockCurrentPage" background :page-size="lockPageSize"
          layout="prev, pager, next" :total="lockTotal">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" style="color: #FFF;background-color: #F56C6C;" class="themecancelButton" @click="lockExpDelete">删除</el-button>
        <el-button class="themecancelButton" @click="lockListVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 积分弹窗 -->
    <el-dialog class="balanceBox" title="积分" :visible.sync="balanceVisible" width="40%">
      <el-table :data="balanceList">
        <el-table-column prop="createdate" label="时间">
        </el-table-column>
        <el-table-column prop="jf" label="积分">
        </el-table-column>
        <el-table-column prop="leixing" label="类型">
        </el-table-column>
      </el-table>
      <div class="chargeToUserBox clearFix">
        <div class="balanceUser">
          <el-input type="number" v-model="balanceUser" placeholder="请输入充值积分数量">

          </el-input>

        </div>
        <div class="groupButton">

          <el-button class="themecancelButton" @click="balanceVisible = false">取消</el-button>
          <el-button class="themeButton" @click="chargeToUser">确定</el-button>
        </div>

      </div>
    </el-dialog>
    <!-- 虚拟实验操作记录弹窗 -->
    <div class="expDialogVisible">
      <el-dialog :title="expDialogTitle" :visible.sync="expDialogVisible" width="70%">
        <el-table :data="expOptionList" stripe style="width: 100%">
          <el-table-column prop="CID" label="关卡" width="100" ></el-table-column>
          <!-- exp_id -->
          <el-table-column prop="exp_id" label="exp_id" width="180" ></el-table-column>
          <el-table-column prop="name" label="name" ></el-table-column>
          <!-- 提交时间 -->
          <el-table-column prop="subtime" label="提交时间" ></el-table-column>
          <!-- 分数 -->
          <el-table-column prop="score" label="分数" width="100" ></el-table-column>
          <!-- 耗时 -->
          <el-table-column prop="duration" label="耗时" width="100" ></el-table-column>
        </el-table>
        <el-pagination @current-change="getExpDataListPage" :current-page.sync="virtualDialogPage" background :page-size="virtualDialogPageSize"
          layout="prev, pager, next" :total="virtualDialogTotal">
        </el-pagination>
      </el-dialog>
    </div>
    <!-- 发微信推送 -->
    <div class="expDialogVisible">
      <el-dialog title="发送微信消息" :visible.sync="sendWxMessageVisible" width="50%">
        <el-input
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 10}"
          placeholder="请输入内容"
          v-model="userWxMessage">
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button class="themecancelButton" @click="sendWxMessageVisible = false">取消</el-button>
          <el-button class="themeButton" @click="sendWxMessage">确定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog title="导出" :visible.sync="exportVisible" width="50%">
      <!-- <div class="datePicker">
        <el-date-picker class="datepickerInput" v-model="exportDate" type="daterange" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期"></el-date-picker>
      </div> -->
      <div style="margin-top: 15px;width: 63%;">
          <el-select v-model="school_id" @change="handleSearchColleague" filterable remote clearable placeholder="请搜索选择学校"
            :remote-method="getschoolList">
            <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="exportVisible = false">关闭</el-button>
        <el-button class="themeButton" type="primary" @click="exportExcel" >确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
// 引入试题列表js
import useList from "@/assets/js/manager/user/userList.js";
export default {
  ...useList
}
</script>

<style>
.dialog-search{
  margin-bottom: 10px;
  width:100%;
  /* float:right; */
  text-align: end;
}
.dialog-search span{
  display: inline-block;
}

.layout .el-dialog__body {
    padding: 10px 20px !important;
}

.el-dialog__body {
    padding: 0px 20px !important;
}

.el-dialog__body {
    padding: 10px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
.useList .el-table .el-table__cell {
  padding: .55vw 0 !important;
}

.useList .el-icon-check,
.useList .el-icon-close {
  font-size: 1vw;
  font-weight: 900;
  text-align: center;
  color: #0D5570;
}

.useList .el-dialog {
  border-radius: 1vw !important;
}

.useList .el-dialog__body {
  text-align: center;
}

.useList .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #0D5570;
  color: #FFF;
}

.useList .el-image {
  cursor: pointer;
  width: 2vw !important;
  height: 2vw !important;
}

.useList .grantBox .el-table__body-wrapper {
  height: 25vw;
  overflow: scroll;
  position: relative;
}

.useList .grantBox .el-checkbox__input.is-checked .el-checkbox__inner,
.useList .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0D5570;
  border-color: #0D5570;
}

.useList .grantBox .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}

.useList .balanceBox .el-table__body-wrapper {
  height: 15vw;
  overflow: scroll;
  position: relative;
}

.useList .balanceBox .el-checkbox__input.is-checked .el-checkbox__inner,
.useList .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0D5570;
  border-color: #0D5570;
}

.useList .balanceBox .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}

.useList .expDialogVisible .el-table__body-wrapper {
  height: 25vw;
  scrollbar-width: none;
  font-size: 0.85vw;
  -ms-overflow-style: none;
  overflow: scroll;
}

.useList .expDialogVisible .el-table__body-wrapper::-webkit-scrollbar {
  display: none;
}

.useList .expDialogVisible .el-dialog__title {
  font-size: 0.85vw;
}

.useList .expDialogVisible .has-gutter {
  font-size: 0.85vw;
}
</style>

<style lang="scss" scoped>
// 引入试题列表组件css
@import "@/assets/css/manager/user/userList.scss";
</style>