import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { encryptCode,decryptCode } from "@/utils/function";
import {request_sign} from '@/utils/const'
import API from '@/api/manager/user/userList.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
    // 页面名称
    name: "userList",
    inject: ['reload'],
    data() {
        return {
            userWxMessage:'',
            sendWxMessageVisible:false,
            lockkeys:[],
            expandedkeys:[],
            expOptionList:[],
            // 虚拟操作记录弹窗
            expDialogVisible:false,
            expDialogTitle:'虚拟活跃度',
            virtualDialogPage:1,
            virtualDialogPageSize:40,
            virtualDialogTotal:0,
            // 统计用户id
            buy_userid:'',
            // 解锁人次
            lockNumber:'',
            balanceUser:'',
            staticDate:[],
            balanceList:[],
            balanceVisible:false,
            // 统计弹窗
            staticVisible:false,
            lockVisible:false,
            checkedAllLock:false,
            tableDataLock: [],
            // 编辑用户弹窗
            edituserVisible:false,
            // 用户ID
            user_id:'',
            grantVisible:false,
            // 授权全选
            checkedAll:false,
            tableData: [],
            // 用户信息
            info:'',
            teacherdescVisible:false,
            // 教师证预览链接
            previewImg:'',
            // 学校名称
            school_name:'',
            // 教师证预览弹窗
            teachercartVisible:false,
            managerstatusList:[
                {
                    id:0,
                    name:'拉黑'
                },
                {
                    id:1,
                    name:'刚进入'
                },
                {
                    id:2,
                    name:'选择身份'
                },
                {
                    id:3,
                    name:'学生已填写信息'
                },
                {
                    id:4,
                    name:'学生已上传面部照片'
                },
                {
                    id:5,
                    name:'学生已上传身份证'
                },
                {
                    id:8,
                    name:'审核未通过'
                },
                {
                    id:9,
                    name:'审核通过'
                },
            ],
            teacherstatusList:[
                {
                    id:0,
                    name:'拉黑'
                },
                {
                    id:1,
                    name:'刚进入'
                },
                {
                    id:2,
                    name:'选择身份'
                },
                {
                    id:8,
                    name:'审核未通过'
                },
                {
                    id:9,
                    name:'审核通过'
                },
            ],
            studentstatusList:[
                {
                    id:0,
                    name:'拉黑'
                },
                {
                    id:1,
                    name:'刚进入'
                },
                {
                    id:2,
                    name:'选择身份'
                },
                {
                    id:3,
                    name:'学生已填写信息'
                },
            ],
            loginstatusList:[
                {
                    id:1,
                    name:'刚进入'
                },
            ],
            typeList:[
                {
                    id:0,
                    name:'学生'
                },
                {
                    id:1,
                    name:'老师'
                },
                {
                    id:9,
                    name:'管理员'
                },
                {
                    id:5,
                    name:'未实名'
                },
                {
                    id:7,
                    name:'学校管理员'
                }
            ],
            type:'',
            is_login:8,
            rules: {
                personal_id: [
                    { required: true, message: '请输入学号', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                school_id: [
                    { required: true, message: '请选择学校', trigger: 'blur' },
                ],
            },
            // 用户信息
            AddFormData:{
                name:'',
                personal_id:'',
                school_id:'',
            },
            // 用户修改学校列表
            userschoolList:[],
            // 用户详情
            form:{
                id:'',
                name:'',
                package:'',
                des:'',
                article_id:'',
                helpurl:''
            },
            // 信息完整总数
            total:'',
            // 分类id
            lab_id:'',
            expList:[],
            expPage:1,
            explabList: [],
            explabListTotal: 0,
            // 根据用户内容搜索
            searchContent: "",
            // 搜索文章
            articleList: [],
            // 当前页码数
            currentPage: 1,
            pageSize: 10,
            exp_id: '',
            dialogVisible: false,
            // 是否正在从远程获取数据
            loading:true,
            title:'',
            schoolList:[],
            school_id:'',
            // 导出弹窗标识
            exportVisible:false,
            labList:[],
            lab_id:'',
            search:'',
            grant_lab_id:'',
            grant_search:'',
            grantListVisible:false,
            grantListTitle:"",
            grantList:[],
            grantCurrentPage:1,
            grantPageSize:10,
            grantTotal:0,
            grantUserId:'',
            grantUserName:"",
            checkedAll:false,
            lockUserId:"",
            lockUserName:"",
            lockListVisible:false,
            lockCheckedAll:false,
            locktListTitle:"",
            lockList:[],
            lockCurrentPage:1,
            lockPageSize:10,
            lockTotal:0,
            grantSearch:"",
            lockSearch:"",



        }
    },
    created() 
    {
        let self = this;
        if(this.$router.currentRoute.query.params)
        {
            let params = JSON.parse(decryptCode(this.$router.currentRoute.query.params));
            self.searchContent = params?.search;
            self.currentPage = Number(params?.page);
            self.is_login = params?.is_login;
            self.type= params?.type;
            self.school_id= params?.school_id;
            self.school_name =  params?.school_name;
            if(self.school_name)
            {
                self.schoolList.push({
                    id: self.school_id,
                    name: self.school_name,
                })
            }
        }
        // console.log(JSON.parse(decryptCode(this.$router.currentRoute.query.params)) );
        self.getData();
        self.getschoolList();
    },
    methods:
    {   lockExpDelete()
        {
            const self = this;
            let array = [];
            self.lockList?.forEach((item) => {
                if(item.check)
                {
                    array.push(item.id);
                }
            })
            if(array.length == 0)
            {
                self.$Tips({
                    // 消息提示内容
                    message: "请选择要删除的实验！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                });return;
            }
            let data = {
                user_id:self.lockUserId,
                array:array
            }
            self.$Loading.show();
            request("POST", `/index.php/adminUser/lockExpDelete`, data)
            .then((result) => {
                self.$Loading.hide();
                self.getLockList();
                self.getData();
            })//*/
        },
        getLockListPage(page)
        {
            const self = this;
            self.lockCurrentPage = page;
            self.lockCheckedAll = false;
            self.getLockList();
        },
        lockToggleAll(check)
        {
            this.lockList?.forEach((item) => {
                item.check = check;
            })
        },
        getLockList(id)
        {
            const self = this;
            if(id !== undefined && id != "")
            {
                self.lockUserId = id
                // self.lockUserName = row.name;
            }
            self.lockListVisible = true;
            self.lockCheckedAll = false;
            self.locktListTitle = "解锁实验列表";
            // if(self.lockUserName != "" && self.lockUserName != undefined)
            // {
            //     self.locktListTitle = row.name + " - 解锁实验列表";
            // }
            let data = {
                user_id:self.lockUserId,
                page:self.lockCurrentPage,
                pageSize:self.lockPageSize,
                search:self.lockSearch
            }
            self.$Loading.show();
            request("POST", `/index.php/adminUser/getLockList`, data)
            .then((result) => {
                self.$Loading.hide();
                self.lockList = result.list;
                self.lockTotal = result.total;
                self.lockSearch = "";
                // console.log(result);
            })//*/ 
        },
        grantExpDelete()
        {
            const self = this;
            let array = [];
            self.grantList?.forEach((item) => {
                if(item.check)
                {
                    array.push(item.id);
                }
            })
            if(array.length == 0)
            {
                self.$Tips({
                    // 消息提示内容
                    message: "请选择要删除的实验！",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                });return;
            }
            let data = {
                user_id:self.grantUserId,
                array:array
            }
            self.$Loading.show();
            request("POST", `/index.php/adminUser/grantExpDelete`, data)
            .then((result) => {
                self.$Loading.hide();
                self.getGrantList();
                self.getData();
            })
        },
        grantToggleAll(check)
        {
            this.grantList?.forEach((item) => {
                item.check = check;
            })
        },
        getGrantListPage(page)
        {
            const self = this;
            self.grantCurrentPage = page;
            self.checkedAll = false;
            self.getGrantList();
        },
        getGrantList(id = "")
        {
            const self = this;
            // console.log(id)
            if(id !== undefined && id != "")
            {
                // console.log(id)
                self.grantUserId = id;
                // self.grantUserName = row.name;
            }
            self.grantListVisible = true;
            self.checkedAll = false;
            self.grantListTitle = "授权实验列表";
            // if(self.grantUserName != "" && self.grantUserName != undefined)
            // {
            //     self.grantListTitle = row.name + " - 授权实验列表";
            // }
            // console.log(self.grantUserId);
            let data = {
                user_id:self.grantUserId,
                page:self.grantCurrentPage,
                pageSize:self.grantPageSize,
                search:self.grantSearch
            }
            self.$Loading.show();
            request("POST", `/index.php/adminUser/getGrantList`, data)
            .then((result) => {
                self.$Loading.hide();
                self.grantList = result.list;
                self.grantTotal = result.total;
                self.grantSearch = "";
                // console.log(result);
            })

        },
        exportExcel()
        {
            this.school_id;
            window.open(
                process.env.VUE_APP_BASEURL + "/adminUser/exportSchoolUseData?school_id="+this.school_id
            );
            this.school_id = '';
            this.getData();
        },
        openExportDialog()
        {
            this.school_id = '';
            this.exportVisible = true;
            this.exportDate = [];
        },
        sendWxMessage()
        {
            let self = this;
            let str = encryptCode({
                user_id :self.user_id,
                userWxMessage :self.userWxMessage,
            })
            let data = {sign:request_sign,params:str}
            nprogressStart();
            self.$Loading.show();
            // 用户发微信推送
            API.sendWxMessage(data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    if (result.code > 0) 
                    {
                        self.sendWxMessageVisible = false;
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 打开虚拟实验列表弹窗
         */
        sendWxMessageDialog(user_id)
        {
            let self = this;
            self.userWxMessage = '';
            self.user_id = user_id;
            self.sendWxMessageVisible = true;
        },
        /**
         * @name: 虚拟实验列表弹窗分页
         */
        getExpDataListPage(page)
        {
            let self = this;
            self.virtualDialogPage = page;
            self.openVirtualDialog(self.user_id,true);
        },
        openVirtualDialog(user_id,pageSign = false)
        {
            let self = this;
            self.expOptionList = [];
            self.user_id = user_id;
            self.expDialogVisible = true;
            if(!pageSign)
            {
                self.virtualDialogPage = 1;
            }
            let str = encryptCode({
                user_id :self.user_id,
                page :self.virtualDialogPage,
                pageSize :10,
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getExpDataListById(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) 
                    {
                        self.expOptionList = result.list;
                        self.virtualDialogTotal = result.total;
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        staticuser()
        {
            let self = this;
            let str = encryptCode({
                startDate:this.staticDate[0],
                endDate:this.staticDate[1],
                buy_userid :this.buy_userid
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getStatisticsUser(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.lockNumber = result.lockNumber
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        showBalance(id)
        {
            let self = this; 
            this.balanceUser = '';
            this.balanceVisible = true;
            let str = encryptCode({
                userid:id,
            })
            self.user_id = id;
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getChargeList(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                    //    console.log(result);
                        self.balanceList = result.data
                        // self.balanceVisible = true
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
            
        },
        /**
         * @name: 解锁项目切换选中效果
         */
        toggleCheckedLock(info)
        {
            if(!info.explist)
            {
                this.tableDataLock.forEach((item)=>{
                    
                    let index = item.explist?.findIndex((key)=>{
                        return key.buystatus == 0
                    })
                    if(index >= 0 )
                    {
                        item.buystatus = false;
                    }
                    else if(item.explist.length == 0)
                    {
                        item.buystatus = false;
                    }
                    else
                    {
                        item.buystatus = true;
                    }
                    
                })
                // let index = this.tableDataLock.findIndex((item)=>{
                //     return item.lab_id == exp.lab_id
                // })
            }

            if(info.buystatus)
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.buystatus = true
                    })
                }
            }
            else
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.buystatus = false
                    })
                }
            }
            
        },
        /**
         * @name: 解锁项目全选
         */
        toggleAllLock(buystatus)
        {
            this.tableDataLock?.forEach((item)=>{
                item.buystatus = buystatus;
                item.explist?.forEach((key)=>{
                    key.buystatus = buystatus;
                })
            })
            // console.log( this.tableDataLock);
        },
        golockProject(id)
        {
            let self = this;
            self.user_id = id;
            // 弹窗
            let str = encryptCode({
                search:self.search,
                lab_id:self.lab_id,
                userid:self.user_id,
                app:'com.mools.chemlab'
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getLockExpList(data)
                .then((result) => {
                    let set = new Set();
                    self.$Loading.hide();
                    if (result.code > 0) {
                        result.data?.forEach((item)=>{
                            item.explist?.forEach((key)=>{
                                key.lab_id = key.id;
                                key.lab_name = key.name;
                                // key.checked = false;
                                key.buystatus = key.buystatus == 0 ?false:true
                            })
                            let lockkeys = item.explist?.find((key)=>{
                                return key.buystatus == true
                            })
                            if(lockkeys)
                            {
                                set.add(item.lab_id)
                            }
                            let index = item.explist?.findIndex((value)=>{
                                return value.buystatus == 0
                            })
                            if(index >= 0)
                            {
                                item.buystatus = false
                            }
                            else if(item.explist.length == 0)
                            {
                                item.buystatus = false
                            }
                            else
                            {
                                item.buystatus = true
                            }
                        })
                        self.lockkeys = Array.from(set)
                        self.tableDataLock = result.data
                        self.labList = result.labList
                        self.lockVisible = true
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 统计
         */
        static(id)
        {
            let self = this;
            self.staticDate = [];
            self.lockNumber = '';
            self.buy_userid = id ;
            self.staticVisible = true;
        },
        closeVisible()
        {
            this.reload();
        },
        /**
         * @name: 跳转授权项目页
         */
        gograntProject(id)
        {
            let self = this;
            self.user_id = id;
            self.expandedkeys = [];
            self.grantVisible = true;
            // 弹窗
            let str = encryptCode({
                user_id:id,
                symbol:'com.mools.chemlab',
                search:self.grant_search,
                lab_id:self.grant_lab_id,
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getGrantExpList(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        let set = new Set()
                        result.data?.forEach((item)=>{
                            item.explist?.forEach((key)=>{
                                key.lab_id = key.id;
                                key.lab_name = key.name;
                                // key.checked = false;
                                key.expliststatus = key.expliststatus == 0 ? false : true
                            })
                            
                            let expandedkeys = item.explist?.find((value)=>{
                                return value.expliststatus == true
                            })
                            if(expandedkeys)
                            {
                                set.add(item.lab_id)
                            }
                            
                            let index = item.explist?.findIndex((value)=>{
                                return value.expliststatus == 0
                            })
                            if(index >= 0)
                            {
                                item.expliststatus = false
                            }
                            else if(item.explist.length == 0)
                            {
                                item.expliststatus = false
                            }
                            else
                            {
                                item.expliststatus = true
                            }
                        })
                        self.expandedkeys = Array.from(set)
                        // console.log(self.expandedkeys);
                        self.tableData = result.data
                        self.labList = result.labList
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        chargeToUser()
        {
            let self = this;
            let str = encryptCode({
                id:  self.user_id,
                amount:self.balanceUser,
            })
            // console.log(
            //     {
            //         id:  self.user_id,
            //         amount:self.balanceUser,
            //     }
            // );
            // console.log({
            //     userid:  self.user_id,
            //     appName:'com.mools.chemlab',
            //     explist : explist.toString()
            // });
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.chargeToUser(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        // self.$router.push('/manager/user/lockProject?params=' +  str);
                        self.getData()
                        self.balanceVisible = false
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        lockProject()
        {
            let self = this;
            let explist = []
            this.tableDataLock.forEach((item) => {
                item.explist?.forEach((key)=>{
                    if(key.buystatus)
                    {
                        explist.push(key.id)
                    }
                })
            })
            let str = encryptCode({
                userid:  self.user_id,
                appName:'com.mools.chemlab',
                buyList : explist.toString()
            })
            // console.log({
            //     userid:  self.user_id,
            //     appName:'com.mools.chemlab',
            //     explist : explist.toString()
            // });
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.updateUserExpLock(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        // self.$router.push('/manager/user/lockProject?params=' +  str);
                        self.getData()
                        self.lockVisible = false
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 授权项目
         */
        grantProject()
        {
            let self = this;
            let explist = []
            this.tableData.forEach((item)=>{
                item.explist?.forEach((key)=>{
                    if(key.expliststatus)
                    {
                        explist.push(key.id)
                    }
                })
            })
            let str = encryptCode({
                userid:  self.user_id,
                appName:'com.mools.chemlab',
                explist : explist.toString()
            })
            // console.log({
            //     userid:  self.user_id,
            //     appName:'com.mools.chemlab',
            //     explist : explist.toString()
            // });
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.updateUserExp(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.getData()
                        self.grantVisible = false
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                        // self.$router.push('/manager/user/grantProject?params=' +  str);
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 切换选中效果
         */
        toggleChecked(info)
        {
            if(!info.explist)
            {
                this.tableData.forEach((item)=>{
                    
                    let index = item.explist?.findIndex((key)=>{
                        return key.expliststatus == 0
                    })
                    if(index >= 0 )
                    {
                        item.expliststatus = false;
                    }
                    else if(item.explist.length == 0)
                    {
                        item.expliststatus = false;
                    }
                    else
                    {
                        item.expliststatus = true;
                    }
                    
                })
                // let index = this.tableData.findIndex((item)=>{
                //     return item.lab_id == exp.lab_id
                // })
            }

            if(info.expliststatus)
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.expliststatus = true
                    })
                }
            }
            else
            {
                if(info?.explist?.length > 0){
                    info.explist.forEach((item)=>{
                        item.expliststatus = false
                    })
                }
            }
            
        },
        /**
         * @name: 全选
         */
        toggleAll(expliststatus)
        {
            this.tableData?.forEach((item)=>{
                item.expliststatus = expliststatus;
                item.explist?.forEach((key)=>{
                    key.expliststatus = expliststatus;
                })
            })
        },
        /**
         * @name: 获取修改用户学校列表
         */
        getUserSchoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    if(result.code > 0 )
                    {
                        self.userschoolList = result.list
                    }
                    // else
                    // {
                    //     Message.error('系统错误')
                    // }
                })                
        },
        getschoolList(e)
        {
            let self=this;
            // 请求参数
            let data = {
                name : e,
                page:1,
                pagesize:10
            };
            // 获取学校列表请求
            API.getSchoolList(data)
                .then((result)=>{
                    if(result.code > 0 )
                    {
                        self.schoolList = result.list
                    }
                })                
        },
        editWeb(id)
        {
            window.open(`#/manager/editWebDetail?id=${id}&detail_id=${id}`)
        },
        editArticle(id,name)
        {
            window.open(`#/teacher/article/articleUpdate?article_id=${id}&name=${name}&hideMenu=1&type=0`)
        },
        /**
         * @name: 改变开放状态
         */
        getexpList(e)
        {
            let self = this;
            // 请求参数
            let data = {
                search: e??'',
                page:self.expPage,
                pagesize:10
            };
            self.$router.push({
                path: "/manager/user",
                query: {
                    page: self.currentPage,
                    search: self.searchContent,
                    lab_id:self.lab_id?self.lab_id:''
                },
                });
            // 获取用户请求
            API.getExpLabList(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.expList.push(...result.logList)
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 改变开放状态
         */
        change(row)
        {
            let self = this;
            for (const key in self.AddFormData) {
                self.AddFormData[key] = row[key]
            }
            self.AddFormData.status = self.AddFormData.status?1:0
            self.AddFormData.pic = JSON.parse(self.AddFormData.pic)
            let data = {
                ...self.AddFormData
            };
            // console.log(data);
            // 添加/修改用户
            API.updateExp(data)
                .then((result) => {
                    if (result.code > 0) {
                        // self.getData();
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        showDialog()
        {
            window.open('#/manager/expDetail');
        },
        showteacherDesc(info)
        {
            this.info = info;
            this.teacherdescVisible = true
        },
        updateuserDesc()
        {
            let self = this;
            // 请求参数
            let str = encryptCode({
                desc: self.info.desc,
                balance:self.info.balance,
                type:self.info.type,
                is_login:self.info.islogin,
                user_id:self.info.id
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取用户请求
            API.updateUserStatus(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                        self.getData();
                        self.teacherdescVisible = false;
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 修改用户信息
         */
        updateUserStatus(info)
        {
            let self = this;
            // 请求参数
            let str = encryptCode({
                desc: info.desc,
                balance:info.balance,
                type:info.type,
                is_login:info.islogin,
                user_id:info.id
            })

            // console.log({
            //     desc: info.desc,
            //     balance:self.type,
            //     type:info.type,
            //     is_login:info.islogin,
            //     user_id:info.id
            // });
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取用户请求
            API.updateUserStatus(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 获取用户
         */
        getData() 
        {
            try {
                let self = this;
                // 请求参数
                let str = encryptCode({
                    search: self.searchContent,
                    type:self.type,
                    school_id:self.school_id,
                    is_login:self.is_login,
                    page:self.currentPage,
                    pagesize:self.pageSize
                })
                let params = encryptCode({
                    school_name:self.school_name,
                    search: self.searchContent,
                    type:self.type,
                    school_id:self.school_id,
                    is_login:self.is_login,
                    page:self.currentPage,
                    pagesize:self.pageSize
                })
                let data = {sign:request_sign,params:str}
                // let data = {}
                self.$router.push({
                    path: "/manager/user",
                    query: {
                        params:params
                    },
                });
                // startLoading();
                self.$Loading.show();
                // 获取用户请求
                API.getUserList(data)
                    .then((result) => {
                        // endLoading(startLoading());
                        self.$Loading.hide();
                        if (result.code > 0) {
                            self.explabList = result.list;
                            self.explabListTotal = result.total - 0;
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        editExp(id)
        {
            let self = this;
            self.updateuser_id = id;
            let str = encryptCode({
                user_id:id,
            })
            let data = {sign:request_sign,params:str}
            self.$Loading.show();
            // 获取虚拟实验分类请求
            API.getUserInfoById(data)
                .then((result) => {
                    self.$Loading.hide();
                    if (result.code > 0) {
                        self.userschoolList.push({
                            id:result.userinfo?.school_id,
                            name:result.userinfo?.schoolName
                        }) 
                        if(result.userinfo)
                        {
                            for (const key in self.AddFormData) {
                                self.AddFormData[key] = result?.userinfo[key];
                            }
                        }

                        self.edituserVisible = true;
                        // console.log(self.AddFormData);
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
            // let params = encryptCode({
            //     user_id:  id,
            // })
            // window.open('#/manager/userInfo?params=' +  params);
        },
        delExpById(id,name)
        {
            let self = this;
            // 请求参数
            let str = encryptCode({
                user_id: id,
            })
            let data = {sign:request_sign,params:str}
            // 删除同事请求
            self.$confirm(`确认要删除${name}吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                API.deleteUser(data)
                    .then((result) => {
                        if (result.code > 0) {
                            // Message.success('删除成功');
                            self.getData();
                            this.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        // else
                        // {
                        //     Message.error('系统错误');
                        // }
                    })
            }).catch(() => {
                // Message.info('已取消');        
            });
        },
        edituser()
        {
            let self = this;
            if(!self.AddFormData.name)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入姓名',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.personal_id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请输入学号',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            if(!self.AddFormData.school_id)
            {
                return self.$Tips({
                    // 消息提示内容
                    message: '请选择学校',
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType: 'warning',
                    displayTime: 1500
                })
            }
            let str = encryptCode({
                user_id: self.updateuser_id,
                ...self.AddFormData
            })
            // console.log({
            //     user_id: self.updateuser_id,
            //     ...self.AddFormData
            // });
            let data = {sign:request_sign,params:str}
            // 添加/修改虚拟实验
            API.updateUserInfo(data)
                .then((result) => {
                    if (result.code > 0) {
                        self.getData();
                        self.edituserVisible = false;
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 添加/修改用户数据
         */
        confirmAdd() 
        {
            try {
                let self = this;
                // 请求参数
                let data = {
                    id: self.form.id,
                    name:self.form.name,
                    // article_id:self.form.article_id,
                    // helpurl:self.form.helpurl,
                    des:self.form.des,
                };
                if(!self.form.id)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类id',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                if(!self.form.name)
                {
                    return self.$Tips({
                        // 消息提示内容
                        message: '请输入分类名称',
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType: 'warning',
                        displayTime: 1500
                    })
                }
                // 添加/修改用户数据
                API.updateExpLabData(data)
                    .then((result) => {
                        if (result.code > 0) {
                            for (const key in self.form) {
                                self.form[key] = ''
                            }
                            self.dialogVisible = false;
                            self.getData();
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'success',
                                displayTime: 1500
                            })
                        }
                        else {
                            // Message.error('系统错误')
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        /**
         * @name: 搜索文章
         */
        getArticleList(e) 
        {
            try {
                let self = this;
                let data = {
                    search: e
                }
                if (e) {
                    API.getArticleList(data)
                        .then((result) => {
                            if (result.code > 0) {
                                self.articleList = result.articleList;
                                self.loading = false
                            }
                            else {
                                // Message.error('系统错误')
                                this.$Tips({
                                    // 消息提示内容
                                    message: result.msg,
                                    // 消息提示类型（success-成功,warning-警告/失败）
                                    messageType: 'warning',
                                    displayTime: 1500
                                })
                            }
                        })
                }
            }
            catch (error) {
                // Message.error('系统错误')
            }
        },
        /**
         * @name: 根据搜索条件,获取用户列表
         */
        handleSearchColleague() 
        {
            let self = this;
            let school = self.schoolList.find((item)=>{
                return item.id == self.school_id
            })
            self.school_name = school?.name
            self.currentPage = 1;
            self.getData()
        },
        preview(img)
        {
            this.previewImg = img
            this.teachercartVisible = true
        },
        updateSortBy(id,sort_no)
        {
            let self = this;
            let data = {
                id: id,
                sort:sort_no
            }
            API.updateSortBy(data)
                .then((result) => {
                    if (result.code > 0) {
                        this.getData();
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'success',
                            displayTime: 1500
                        })
                    }
                    else {
                        // Message.error('系统错误')
                        this.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType: 'warning',
                            displayTime: 1500
                        })
                    }
                })
        },
        /**
         * @name: 用户表格分页序号递增
         */
        indexMethod(index) 
        {
            let currentPage = this.currentPage - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },

        formatDate(dateStr) 
        {
            const date = new Date(dateStr);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }
    }
};